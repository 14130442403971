<template>
  <div class="coupon_info">
    <div class="head">Coupon Information</div>
    <div class="body">
      <div class="inner">
        <div v-loading="loading" class="info">
          <div class="detail">
            <div class="detail_item">
              <span class="key_name">Chamber of Commerce:</span>
              <span class="value">{{chamberOfCommerceName}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">eCO Company Name:</span>
              <span class="value">{{coCompanyName}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Number of Coupons:</span>
              <span class="value" :class="{ warning_message: numberOfCoupons === 0 }">{{numberOfCoupons}}</span>
            </div>
            <div class="detail_item">
              <span class="key_name">Date:</span>
              <span class="value">{{quantityGetDateTime}}</span>
            </div>
          </div>
          <div class="check_button">
            <tw-button type="secondary" size="medium" @click="checkCoupon" :disabled="noCoSigherId">Check Coupon</tw-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import TwButton from '@/components/atoms/TwButton';

export default {
  name: 'TwCouponInformation',
  inject: ['s'],
  components: {
    TwButton
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    loading() {
      return _.get(this, 's.couponInfo.loading');
    },
    chamberOfCommerceName() {
      return _.get(this, 's.couponInfo.chamberOfCommerceName');
    },
    coCompanyName() {
      return _.get(this, 's.couponInfo.coCompanyName');
    },
    numberOfCoupons() {
      return _.get(this, 's.couponInfo.numberOfCoupons');
    },
    quantityGetDateTime() {
      return this.$options.filters.dateTimeFormat(_.get(this, 's.couponInfo.quantityGetDateTime', ''));
    },
    // Check Couponボタンのdisabled判定
    noCoSigherId() {
      // coSignerId1が設定されていない場合はクーポン情報を取得できないため、Check Couponボタンをdisabledにする
      const coSignerId1 = _.get(this, 's.cloneItems.linkageFinalDocs.certificateOfOriginGrp.coSignerId1');
      return !(coSignerId1 && _.isString(coSignerId1));
    }
  },
  watch: {},
  methods: {
    checkCoupon() {
      this.s.checkCoupon();
    }
  }
};
</script>

<style lang="scss" scoped>
.coupon_info {
  display: flex;
  margin: 8px 16px 0;
  font-size: 14px;
  line-height: 20px;
  color: $color_gray_800;
  .head {
    display: flex;
    align-items: center;
    background: $color_gray_300;
    border-radius: 6px 0px 0px 6px;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    padding: 0 16px;
    width: 140px;
  }
  .body {
    background: #ffffff;
    border: 1px solid $color_gray_300;
    border-radius: 0px 6px 6px 0px;
    width: 100%;
  }
  .inner {
    display: flex;
    height: auto;
    .info {
      display: flex;
      width: 100%;
      min-height: 96px;
      padding: 16px;
    }
    .detail {
      .detail_item {
        display: flex;
        margin-bottom: 4px;
        color: $color_gray_800;
        font-size: 14px;
      }
      .key_name {
        margin-right: 8px;
        white-space: nowrap;
      }
      .value {
        &.warning_message {
          color: $color_warning;
          font-weight: bold;
        }
      }
    }
    .check_button {
      min-width: 180px;
      margin-left: auto;
      text-align: right;
    }
  }
}
</style>
